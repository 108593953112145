import {useRouter, withRouter} from 'next/router';
import {BREAKPOINT} from '../../lib/styles';
import {replaceHtmlEntities} from 'aac-components/utils/helpers';

import IconTwitter from 'aac-components/components/Icons/Social/IconTwitter';
import IconFacebook from 'aac-components/components/Icons/Social/IconFacebook';
import IconMail from 'aac-components/components/Icons/Misc/IconMail';
import IconShare from 'aac-components/components/Icons/Basic/IconShare';
import {useContext} from 'react';
import AppContext from '../AppContext';

function removeTrailingSlash(url) {
    const lastChar = url.substr(-1);
    if (lastChar === '/') {
        url = url.substring(0, url.length - 1);
    }
    return url;
}

const Share = () => {
    const {asPath} = useRouter();
    const {pageProps: {data = {}} = {}} = useContext(AppContext);

    const {title: {rendered: title = ''} = {}, tags = []} = data;

    // sharable title
    let shareTitle = replaceHtmlEntities(title);
    shareTitle = shareTitle?.replace(/&/g, 'and'); // remove '&', breaks url string

    const url = removeTrailingSlash(`https://americanaddictioncenters.org${asPath}`);

    return (
        <div>
            <IconShare fill="var(--gray-400)" />
            <span>Share</span>
            <a
                target="_blank"
                href={`https://twitter.com/intent/tweet?url=${url}&text=${shareTitle}`}
                style={{backgroundColor: '#00a2ea'}}>
                <IconTwitter fill="#fff" width="20" height="20" />
            </a>
            <a
                target="_blank"
                href={`https://www.facebook.com/sharer.php?u=${url}`}
                style={{backgroundColor: '#344f8d'}}>
                <IconFacebook fill="#fff" width="20" height="20" />
            </a>
            <a
                target="_blank"
                href={`mailto:?subject=${shareTitle}&body=${url}`}
                style={{backgroundColor: '#ff6c03'}}>
                <IconMail fill="#fff" width="20" height="20" />
            </a>
            <style jsx>{`
                div {
                    display: flex;
                    align-items: center;
                }
                span {
                    color: var(--gray-400);
                    font-weight: bold;
                    font-size: 13px;
                    margin: 0 15px 0 5px;
                    text-transform: uppercase;
                }
                a {
                    height: 30px;
                    width: 30px;
                    margin-right: 5px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 3px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    a {
                        height: 30px;
                        width: 30px;
                    }
                }
                i::before {
                    font-size: 16px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    i::before {
                        font-size: 20px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Share;
