import React from 'react';
import {shouldShowError} from '../../lib/utils';
import Layout from '../../components/Layout';
import Image from 'next/image';
import {shortcodes} from '../../lib/shortcodes';
import {BREAKPOINT} from '../../lib/styles';
import CustomError from '../_error';
import Share from '../../components/Share';
import ContactForm from 'aac-components/components/ContactForm';
import {getPageByPath} from '../../lib/post';
import {globalBlurDataUrl} from '../../lib/globals';
import {MAX_WIDTH_NARROW_PX, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import ContactFormAbandonModal from 'aac-components/components/ContactForm/ContactFormAbandonModal';

const SocailPrSingle = (props) => {
    const {data = []} = props;

    // error page
    const hasError = shouldShowError(props);
    if (hasError) {
        return <CustomError statusCode={hasError} />;
    }

    const {
        content: {rendered: content = ''} = {},
        title: {rendered: title = ''} = {},
        excerpt: {rendered: excerpt = ''} = {},
        featured_image = [],
        acf = {},
    } = data;

    const {aac_social_pr_show_form: showForm} = acf;

    const featuredImage = featured_image?.[3];

    const formTitle = data?.acf?.aac_social_pr_form_title || 'Contact Us';
    const formDesc = data?.acf?.aac_social_pr_form_description || '';

    /**Video Section */
    const showVideoContent = acf?.aac_social_pr_show_videos_section;
    const videoContent = acf?.aac_social_pr_videos_section || '';

    /**Related Articles */
    const showRelatedArticles = acf?.aac_social_pr_show_related_articles;
    const relatedArticlesHeader = acf?.aac_social_pr_related_articles_header || '';
    const articles = [
        {
            title: acf?.aac_social_pr_related_article_1_title,
            link: acf?.aac_social_pr_related_article_1_link,
            img: acf?.aac_social_pr_related_article_1_img,
            desc: acf?.aac_social_pr_related_article_1_desc,
        },
        {
            title: acf?.aac_social_pr_related_article_2_title,
            link: acf?.aac_social_pr_related_article_2_link,
            img: acf?.aac_social_pr_related_article_2_img,
            desc: acf?.aac_social_pr_related_article_2_desc,
        },
        {
            title: acf?.aac_social_pr_related_article_3_title,
            link: acf?.aac_social_pr_related_article_3_link,
            img: acf?.aac_social_pr_related_article_3_img,
            desc: acf?.aac_social_pr_related_article_3_desc,
        },
    ];

    // addiction-talk page
    const isAddictionTalkPage = data?.slug === 'addiction-talk';

    return (
        <Layout>
            <div className="hero">
                <Image
                    src={featuredImage}
                    blurDataURL={globalBlurDataUrl}
                    alt="American Addiction Centers"
                    placeholder="blur"
                    fill
                    style={{objectFit: 'cover'}}
                />
                <div className="hero__inner">
                    <div className="hero__content">
                        <h1 dangerouslySetInnerHTML={{__html: title}} />
                        <div dangerouslySetInnerHTML={{__html: excerpt}} />
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="content__block">
                    {isAddictionTalkPage && (
                        <>
                            <h3 className="featured-media__header">
                                We have been featured in
                            </h3>
                            <div className="featured-media">
                                {addictionTalkMedia.map((media, index) => {
                                    const rowClass =
                                        index <= 5 ? 'top-row' : 'bottom-row';
                                    return (
                                        <a
                                            className={`featured-media__logo ${rowClass}`}
                                            href={media.link}
                                            target="_blank"
                                            key={media.title}>
                                            <Image
                                                alt={media?.title}
                                                src={media?.image}
                                                defer={true}
                                                with="auto"
                                                height="auto"
                                            />
                                        </a>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
                {showVideoContent && videoContent && (
                    <div className="content__block video-section">
                        <div
                            className="video-section__inner"
                            dangerouslySetInnerHTML={{__html: videoContent}}
                        />
                    </div>
                )}
                {showForm && (
                    <div className="content__block form-section">
                        <div className="section-header">
                            <h2
                                dangerouslySetInnerHTML={{__html: formTitle}}
                                style={{textAlign: 'center'}}
                            />
                            <div dangerouslySetInnerHTML={{__html: formDesc}} />
                        </div>
                        <div className="form-container">
                            <ContactFormAbandonModal>
                                <ContactForm leadSource="WEB-SOCIAL-" />
                            </ContactFormAbandonModal>
                        </div>
                    </div>
                )}
                {showRelatedArticles && (
                    <div className="content__block">
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: relatedArticlesHeader,
                            }}
                            style={{textAlign: 'center', marginBottom: '40px'}}
                        />
                        <div className="articles-container">
                            {Array.isArray(articles) &&
                                articles.map((item) => {
                                    return (
                                        <div key={item?.link}>
                                            <ArticleCard item={item} key={item?.link} />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
                <div className="share">
                    <Share />
                </div>
            </div>

            <style jsx>{shortcodes}</style>

            <style jsx>
                {`
                    h1 {
                        text-align: center;
                    }
                    .hero {
                        display: block;
                        position: relative;
                        padding: 40px 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            padding: 80px 0 120px 0;
                        }
                    }
                    .hero__inner {
                        position: relative;
                        background: linear-gradient(
                            359.75deg,
                            rgba(229, 235, 245, 0.8) 0.19%,
                            rgba(239, 246, 247, 0.8) 97.73%
                        );
                        padding: 40px 15px;
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 15px 0 15px;
                        text-align: center;
                        border-radius: 8px;
                        box-shadow: var(--box-shadow);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__inner {
                            padding: 80px 40px;
                            margin: 0 auto;
                        }
                    }
                    .content {
                        position: relative;
                        display: block;
                    }

                    .content__block {
                        border-radius: 3px;
                        padding: 26px;
                        margin-bottom: 80px;
                        background: #fff;
                        max-width: ${MAX_WIDTH_PX};
                        margin: 40px auto;
                        padding: 0 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .content__block {
                            margin: 80px auto;
                        }
                    }

                    .form-container {
                        border-radius: 3px;
                        max-width: 700px;
                        margin: 0 auto;
                        text-align: left;
                    }
                    .section-header {
                        margin-bottom: 50px;
                    }
                    .articles-container {
                        display: block;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .articles-container {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                    .featured-media__header {
                        text-align: center;
                        margin: 0 auto 30px auto;
                    }
                    .featured-media {
                        margin-bottom: 48px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        grid-gap: 12px;
                    }
                    .featured-media__logo {
                        text-align: center;
                        min-width: 145px;
                    }
                    .video-section {
                        background: linear-gradient(
                            103.99deg,
                            rgba(244, 249, 249, 0.4) 0%,
                            rgba(248, 238, 229, 0.4) 100%
                        );
                        max-width: 100%;
                    }
                    .video-section__inner {
                        max-width: ${MAX_WIDTH_NARROW_PX};
                        margin: 0 auto 80px auto;
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .video-section__inner {
                            padding: 80px 0;
                        }
                    }
                    .share {
                        margin: 0 auto 80px auto;
                        max-width: fit-content;
                    }
                `}
            </style>
        </Layout>
    );
};

export default SocailPrSingle;

export async function getServerSideProps(context) {
    let data = await getPageByPath(context);
    const id = data?.id || 0;
    const slug = data?.slug || '';

    // set 404 status code
    if (id === 0 || data?.type !== 'social-pr') {
        context.res.statusCode = 404;
    }
    return {
        props: {
            data,
        },
    };
}

const addictionTalkMedia = [
    {
        title: 'People Magazine',
        image: '/static/pr-logos/people-logo.jpg',
        link: 'https://people.com/tv/bachelor-ben-higgins-opens-up-about-his-past-painkiller-addiction/',
    },
    {
        title: 'Page Six',
        image: '/static/pr-logos/page-six.jpg',
        link: 'https://pagesix.com/2021/03/25/bachelor-star-ben-higgins-reflects-on-past-painkiller-addiction/    ',
    },
    {
        title: 'BET',
        image: '/static/pr-logos/bet-logo.jpg',
        link: 'https://www.bet.com/celebrities/news/2021/08/20/lamar-odom-claims-he-was-drugged-in-near-fatal-brothel-overdose.html',
    },
    {
        title: 'Insider',
        image: '/static/pr-logos/insider-logo.jpg',
        link: 'https://www.insider.com/bachelor-ben-higgins-past-painkiller-addiction-2021-4?amp',
    },
    {
        title: 'Blavity',
        image: '/static/pr-logos/blavity-logo.jpg',
        link: 'https://blavity.com/blavity-original/sober-brown-girls-is-a-rare-safe-space-for-women-of-color-looking-to-overcome-addiction?category1=news&category2=life-style',
    },
    {
        title: 'Showbiz CheatSheet',
        image: '/static/pr-logos/showbiz-cheatsheet-logo.jpg',
        link: 'https://www.cheatsheet.com/entertainment/lamar-odom-recalls-kar-jenners-pulling-up-like-a-team-save-life.html/',
    },
    {
        title: 'Fox News',
        image: '/static/pr-logos/fox-news-logo.jpg',
        link: 'https://www.foxnews.com/entertainment/lamar-odom-drugged-near-fatal-overdose',
    },
    {
        title: 'Treatment Magazine',
        image: '/static/pr-logos/treatment-magazine-logo.jpg',
        link: 'https://treatmentmagazine.com/feature-story/the-hard-knocks-dont-end-on-the-field/',
    },
    {
        title: 'Daily Mail',
        image: '/static/pr-logos/daily-mail-logo.jpg',
        link: 'https://www.dailymail.co.uk/tvshowbiz/article-9910125/Lamar-Odom-claims-drugged-night-near-fatal-overdose-Nevada-brothel-2015.html',
    },
    {
        title: 'E! Online',
        image: '/static/pr-logos/e-news-logo.jpg',
        link: 'https://www.eonline.com/news/1252266/bachelors-ben-higgins-recalls-taking-pills-from-his-grandfather-amid-past-addiction-battle',
    },
    {
        title: 'Patriots News',
        image: '/static/pr-logos/patriots-logo.jpg',
        link: 'https://www.patriots.com/news/matthew-slater-speaks-on-addiction-in-the-nfl-panel-it-just-takes-people-who-car',
    },
];

const ArticleCard = (props) => {
    let {item: {title = '', img = '', desc = '', link = ''} = {}} = props;
    if (!title || !link) return null;
    return (
        <div {...props}>
            <a href={link} title={title} rel="noreferrer nofollow" target="_blank">
                <div className="article">
                    <div className="article__img">
                        <Image
                            src={img}
                            placeholder="blur"
                            blurDataURL={globalBlurDataUrl}
                            fill
                            style={{objectFit: 'cover'}}
                            sizes="33vw"
                            alt={`${title} Image`}
                        />
                    </div>
                    <div className="article__label">
                        <div
                            className="text-md"
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                    </div>
                    <hr />
                    <div className="text-sm description">
                        <span dangerouslySetInnerHTML={{__html: desc}} />
                    </div>
                </div>
            </a>

            <style jsx>
                {`
                    a {
                        text-decoration: none;
                        color: #000;
                    }
                    hr {
                        margin: 0px 10px;
                    }
                    .text-md {
                        text-align: left;
                        font-weight: bold;
                    }
                    .text-sm {
                        text-align: left;
                    }
                    .article {
                        box-shadow: var(--box-shadow);
                        height: auto;
                        overflow: hidden;
                        padding: 16px;
                        max-width: 100%;
                        background: #fff;
                        border-radius: 8px;
                        margin: 0 15px 25px 15px;
                        margin: 20px auto;
                        position: relative;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .article {
                            margin: 0 15px 25px 15px;
                        }
                    }
                    .article__img {
                        position: relative;
                        width: 100%;
                        height: 200px;
                        border-radius: 4px;
                        overflow: hidden;
                    }
                    .article:hover {
                        transform: scale(1.02);
                        transition: all 0.5s;
                    }
                    .article__label {
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        padding: 10px;
                    }

                    .location {
                        color: #a1a1a1;
                    }
                    .description {
                        padding: 5px 10px;
                        text-align: left;
                    }
                `}
            </style>
        </div>
    );
};
